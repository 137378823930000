.main {
  width: 100%;
  margin: 0 0 100px 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.header{
  width: 100%;
  display: flex;
  
}
.settingsIcon {
  margin: 10px 10px 5px auto;
  padding:0 0px 0 0;
  display: flex;
  justify-content: flex-end;
  color: black;
  transition: all 100ms ease-in;
}
.settingsIcon:hover{
  color: var(--clr-primary);
  cursor: pointer;
}

.carousel{
  height: 500px;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

