.thumbnail-icon-container{
    
    width: 100%;
    display: flex;
    flex-wrap:nowrap;
    padding: 0;
    margin: 5px 0 0 0 ;
    align-items: center;
    justify-content: center;
    
    gap: 5px;
    flex: 1;
    
}