.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 10000;
  overflow: hidden;

  width: 90%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-filter: blur(0.000001px);
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: rgba(0, 0, 0, 0.75);
}
