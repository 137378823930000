.filterMenu{
    
    
   
    text-align: center;
    padding: auto;
    display: flex;
    flex-direction: column;
    
}
.title{
    font-size: 1.5rem;
    font-weight: 900;
}
.filterMenu > span{
    font-size: 1.5rem;
    font-weight: 900;
    color: var(--clr-primary-dark);
}

.addFilter{
    background-color: var(--clr-primary); 
    border: none;
    color:white;
    border-radius: 15px;
    height: 25px;
    font-size: .8rem;
    font-weight: 400;
    cursor: pointer;
    padding: 2px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-right: 5px;
    transition: all 200ms ease-out;
    
    
}

.addFilter:hover{
    background-color:var(--clr-primary);
    transform: scale(1.05);
}
.addFilterContainer{
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    flex:1;
    align-self: flex-end;
}