.barChartContainer{
    width: 100%;
    
    border: 1px solid black;
    padding: 10px;
    
    min-width:0;
    min-height: 0;
    overflow-y: overlay;
}

.wrapper{
    position: relative;
    width: 100%;
    
}