.GroupMenu{
   
    
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: auto;
    width: 100%;
}

.GroupMenu > span{
    font-weight: 900;
    font-size: 1.5rem;
    color: var(--clr-primary-dark);
}

.groupSelect{
    width: 90%;
}