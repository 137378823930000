.asset-metric {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  height: 90%;
  min-width: 150px;
  min-height: 80px;
  border: 0.25px black solid;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  
}

.header {
  width: 100%;
  height: 15px;
  background-color: none;
  font-size: 0.6rem;
  font-weight: 800;
  margin-left: 10px;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
}

.metric {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  font-weight: 900;
  font-size: clamp(0.688rem, 3vw - 1rem, 2.188rem);
  color: var(--clr-primary);
  margin: 0;
  flex: 1;
}

.comparison-bar-graph {
  width: auto;
  display: flex;
  align-items: flex-end;
  justify-content: stretch;
  gap: 10px;
  margin-bottom: 0px;
  padding: 0;
  flex: 0;
  margin-right: 5px;
  
}

.bar {
  
  width: 40px;
  display: flex;
  justify-content: center;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  transition: all .5s ease-in;
}

.bar::before {
  content: attr(data-before);
  font-size: clamp(0.125rem, 3vw - 1rem, 0.75rem);
  font-weight: 700;
  color: black;
  top: -15px;
  position: absolute;
}

.color-primary{
  background-color: var(--clr-primary);
}

.color-dark{
  background-color: var(--clr-primary-dark);
}