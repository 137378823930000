.sort-toggle{
    margin: auto;
    
    display:flex;
    align-items: center;
    color:rgba(0,0,0);
    transition: all .1s ease-out;
}

.sort-toggle:hover{
    
    color:#006b99;
    cursor: pointer;
}