.table{
    
    width: 100%;
    border-spacing: 0;
    margin-inline: auto;
    
    overflow: hidden;
    
    border: 1px solid black;
    margin-bottom: .25rem;
    
}

.table th {
    padding: 6px 0px;
    color: white;
    font-weight: 600;
    text-align: center;
    background-color: var(--clr-primary);
    font-size: .65rem;
    border-bottom: 1px solid black;
    
}

.table thead tr{
    border: 1px solid black;
} 
.table tr{
    padding: 4px 10px;
    
    text-align: center;
    font-size: .75rem;
    font-weight: bold;
}

.table td{
    padding: 6px 10px;
}
.tbody tr:last-child td{
    border-top: 1px solid black;
    
}


.sortable{
    cursor: pointer;
}