.metricBox{
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 0px 2px;
    border: 0.5px solid black;
    gap: 0;
    line-height: 1rem;
}

.metricBox span{
    padding: 0;

}

.title{
    font-size: .75rem;
    font-weight: 800;
    color: var(--clr-primary)
}

.value{
    font-size: .65rem;
    font-weight: 500;
}