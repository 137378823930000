.timeSeriesContainer {
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  
  margin-top: 1rem;
  display: block;
  position: relative;
  box-shadow: 2px 5px 10px rgba(0,0,0,.1);
}
 