.add-filter-container {
  padding: 10px;
  border: 4px solid var(--clr-primary);
  background-color: white;
  overflow:visible;
  margin: 10px;
  width: 80%;
  height: 80%;
  box-shadow: 0px 0px 15px -5px;
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10px;
  flex-direction: column;
  overflow-y: auto;
}

.addFilter{
  background-color: var(--clr-primary); 
  border: none;
  color:white;
  border-radius: 15px;
  height: 25px;
  font-size: .8rem;
  font-weight: 400;
  cursor: pointer;
  padding: 2px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  transition: all 200ms ease-out;
  
  
}
.addFilterDisabled{
  
  border: none;
  
  border-radius: 15px;
  height: 25px;
  font-size: .8rem;
  font-weight: 400;
  cursor: pointer;
  padding: 2px 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 5px;
  transition: all 200ms ease-out;
  background-color: lightgrey; 
  

  color:darkgrey;
  
  cursor: not-allowed;
  
}

.addFilter:hover{
  background-color:var(--clr-primary);
  transform: scale(1.05);
}
.filterName {
  margin: 0px 5px;
  width: 250px;
  font-size: 1rem;
  font-weight: 500;
  
}

.filterName {
  outline: 0;
  border-width: 0 0 2px;
  border-color: grey;
  background-color: transparent;
}
.filterName:focus {
  outline: none;
  border-color: var(--clr-primary);

}

.filterName:active {
  outline: none;
}

.filterNameFieldset{
  padding: 5px 2px;
  border-width: .5px;
  border-radius: 4px;
  
}
.filterNameFieldsetLegend{
  font-size: .75rem;
}


.xButton{
  position: absolute;
  margin-right: 3px;
  top:0px;
  right: 0px;
  z-index:10;
  color:var(--clr-primary-dark);
  transition: all 200ms ease;
}

.xButton:hover{
  color: rgba(255, 71, 71, 0.844);
  cursor: pointer;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.orClauseContainer{
  padding: 20px;
  
  width: 100%;
  display:flex;
  flex-direction: column;
  gap: 10px;
}




.clauseContainer{
  width:100%;
}

.save-button-container{
  text-align: right;
}