.clause-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
   position: relative;
   color: black;
}



.close-button{
    color:black;
    margin-left:auto;
    background-color: transparent;
}

.close-button-text{
    padding:0;
    margin:0;
    color:black;
    align-self: center;
    font-size: 1.5rem;
}

.close-button-text:hover{
    color:crimson;
}

.comparisonInput::-webkit-outer-spin-button,
.comparisonInput::-webkit-inner-spin-button {
  
  

}

/* Firefox */
.comparisonInput[type=number] {

  margin-right:  10px;
}

.comparisonInput{
    background-color: white;
    border: 1px rgba(0,0,0,.05) solid;
    border-radius: 3px;
    height: 25px;
}

.comparisonInput:focus {
    outline: none;
    border-color: var(--clr-primary);
  
  }

.orButton{
    margin-left:auto;
    cursor: pointer;
    border: none;
    background: var(--clr-primary-dark)
    
}



.orButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px 7px;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  border-radius: 6px;
  border: none;

  color: #fff;
  
   background-origin: border-box;
  box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  font-size: .75rem;
  transition: transform 200ms ease-out;
}

.orButton:hover{
    transform: scale(1.1);
}


.orLabel{
    position: absolute;
    z-index: 10000;

    font-size: .5rem;
    left: -24px;
    bottom: -18px;
    font-weight: 800;
}
.deleteClause{
    
    
}
.hidden {
    visibility: hidden;
    
}
.deleteClause:hover{
    color:crimson;
    cursor: pointer;
}