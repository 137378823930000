.summaryBox{
    display: flex;
    flex-direction: column;
    place-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    border: .5px black solid;
    border-radius: 5px;
    padding: 0 5px;
    background-color: rgba(0,0,0,.05);
}

.summaryBox h1{
    color: var(--clr-primary);
    font-size: 1rem;
    font-weight: 800;
    
}