.main {
  background-color: rgba(120, 32, 240, 0.1);
  margin-inline: auto;
  margin-top: 2rem;
  width: 100%;
}
.aggregates {
  width: 100%;
 
  margin-inline: auto;
  margin-bottom: 200px;
}
.container {
  width: 90%;
  margin-top: auto;
  margin-inline: auto;
  border: 1px solid black;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
}
