.circle{
    width: 2rem;
    height: 2rem;
    background-color: rgb(237, 128, 4);
    margin:0;
    padding:0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: white;
    font-weight: 600;
}