.main {
  width: 100%;
  margin: 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.settingsIcon {
  margin: 10px 10px 5px auto;
  padding: 0 0px 0 0;
  display: flex;
  justify-content: flex-end;
  color: black;
  transition: all 100ms ease-in;
}

.settingsIcon:hover {
  color: var(--clr-primary);
  cursor: pointer;
}

.barCharts {
  margin-inline: auto;
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
}
.aggregateGroup {
  margin-left: auto;
  margin-right: 10px;
}
.aggregates {
  width: 90%;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-inline: auto;
  margin-bottom: 200px;
}
.aggregates h1 {
  font-size: 2rem;
  color: var(--clr-primary-dark);
  font-weight: 900;
}
.topAssetsContainer {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin-inline: auto;
  
  gap: 10px;
  margin-bottom: 20px;
}
.topAssets{
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: 20px;
}
.topAssets h1 {
  font-size: 2rem;
  color: var(--clr-primary-dark);
  font-weight: 900;
}

.barChartWrapper{
  width: 100%;
  aspect-ratio: 16/9;
  overflow: visible;
    padding: 10px;
}