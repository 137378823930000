.filtermenuitem{
    
    width: 100%;
    padding: 0px 5px;
    border-top: 1px  solid black;
    
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;


}

.lastItem{
    border-bottom: 1px  solid black;
}

.filter-button{
    background-color: rgba( 255,255 , 255, 1);
    border-radius: 10px;
    padding: 1px 5px;
    border: none;
    margin: 2px;
    cursor: pointer;
    transition: all 200ms ease-out;
    font-size: .75rem;
}

.filter-button:hover{
    background-color:rgb(50, 176, 240,1);
}

.remove:hover{
    background-color:rgb(227, 27, 50);
}

.deleteFilter:hover{
    color:crimson;
    cursor: pointer;
}
.filteritembuttons{
    display: flex;
    gap: 2px;
}

.filterName{
    color:black;
    font-size: .75rem;
    font-weight: 700;
}