.timeSeriesContainer {
  width: 75%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  margin-top: 1rem;
  display: block;
  position: relative;
}
