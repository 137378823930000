.asset-card-container {
  width: 90%;
  height: 90%;

  display: flex;
  flex-direction: column;
  place-items: center;

  margin: 0;
  padding: 20px 20px 5px 20px;

  overflow: hidden;
  overflow-y: auto;

  border: 4px solid var(--clr-primary);
  border-radius: 20px;

  background-color: white;

  transform: width 250ms ease-in;
}


.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 10000;
  overflow: hidden;


  -webkit-font-smoothing: antialiased;
  -webkit-filter: blur(0.000001px);
}

.close-button {
  position: absolute;
  margin-right: 4px;
  top: 0px;
  right: 0px;
  z-index: 10;
  color: var(--clr-primary-dark);
  transition: all 200ms ease;
}
.close-button:hover {
  color: var(--clr-close-button);
  cursor: pointer;
}

.main-content {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 0.5fr repeat(7, 1fr);

  gap: 10px;
}

.asset-title {
  height: auto;

  grid-column: 2/4;

  margin: 5px;

  text-align: center;

  color: black;
  font-size: 2rem;
  font-weight: 800;
}

.language-french {
  color: var(--clr-language-french);
}

.language-english {
  color: var(--clr-language-english);
}




.assetAnalysis {
  text-align: center;
  height: 100%;
  border: 1.5px black solid;
  margin: 5px 10px 0px 10px;
  padding: 0px 10px;
  border-radius: 10px;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.5);

  background-color: rgba(0, 0, 0, 0.1);
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 10px;
  overflow: auto;
}
