.main {
    width: 100%;
    height: 100%;
    padding: 0 0 500px 0;
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
  .datePickerWrapper{
    margin-left: auto;
    margin-right: 0;
    width: 100%;
  }
  .header {
    width: 100%;
    display: flex;
  }
  .settingsIcon {
    margin: 10px 10px 5px auto;
    padding: 0 0px 0 0;
    display: flex;
    justify-content: flex-end;
    color: black;
    transition: all 100ms ease-in;
  }
  .settingsIcon:hover {
    color: var(--clr-primary);
    cursor: pointer;
  }
  
  .carousel {
    height: 500px;
  }
  .carousel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .presetSelector {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
  
  .presetSelector span {
    font-size: 1.5rem;
    color: var(--clr-primary);
    font-weight: 800;
  }
  
  .presetContainer {
    width: 70%;
    display: flex;
    margin-inline: auto;
    justify-content: space-evenly;
    margin-top: 15px;
    padding-bottom: 15px;
    border: 1px solid var(--clr-primary);
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);
  }
  
  .executivesummary {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    margin-inline: auto;
    margin-top: 0px;
  }
  
  .barCharts {
    margin-inline: auto;
    margin-top: 20px;
    width: 100%;
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    padding: 0;
    gap: 20px;
  }
  
  .aggregates {
    width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
    margin-inline: auto;
    margin-bottom: 200px;
    margin-top: 50px;
  }
  .aggregates h1 {
    font-size: 2rem;
    color: var(--clr-primary-dark);
    font-weight: 900;
  }

  .datePicker{
    text-align: center;
    width: auto;
  }
  
  .datePicker:hover{
    cursor: pointer;
  }

  .tableContainer{
    margin-inline: auto;  
    width: 95%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 20px
  }



  .cpmTable {
    border-spacing: 0;
  
    border-top: 0.5px solid black;
    border-left: 0.5px solid black;
    width: 100%;
    box-shadow: 5px 5px 10px rgba(0,0,0,.2);
    margin-inline: auto;
    margin-top: .5rem;
    text-align: center;
  /*  margin-top: 20px;
    margin-bottom: 20px;*/
  }
  .platformCell{
      background-color: white;
      color: black;
      height: 40%;
      width: 10%;
      font-size: 1.5rem;
      font-weight: 700;
      border-bottom: 2px solid black;
  }
  
  .platformContainer{
      max-width: 100%;
      display: flex;
      flex-direction: column;
      place-items: center;
  }
  
  .journeyStage { 
      background-color: var(--clr-primary);
      color: white;
      font-size: 1.25rem;
  
    }
  
    .resultType{
      text-align: center;
      color: black;
      font-weight:800;
    }
  .cpmTable th {
    
    text-align: center;
    border-collapse: collapse;
    font-size: 1.5rem;
    font-weight: 800;
    
  }
  .rowTitle {
    font-weight: 800;
    text-align: center;
    max-width: 20%;
  }
  
  .cpmTable th,
  
  .cpmTable td {
    padding: 0.25rem;
    border-right: 0.5px solid black;
    border-bottom: 0.5px solid black;
    padding-inline: auto;
    font-size: 1.25rem;
  }
  
  .rowData {
    text-align: center;
    font-weight: 500;
  }
  
  .actualColumn {
    display: flex;
  
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  
  .actualColumn td {
    height: 0;
  }
  

  .cpmTable th { 
    background-color: var(--clr-primary);
    color: white;
    font-size: 1.25rem;

  }


  .lightred{
    background-color: rgb(240,202,201);
    font-size: 1.5rem;
  }

  .lightgreen{
    background-color: rgb(217,234,211);
    font-size: 1.5rem;
  }

  
  .brightgreen{
    background-color: rgb(68, 255, 0);
    font-size: 1.5rem;
  }

  .awarenessContainer{
    margin-inline: auto;
    margin-top: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    place-items: center;
  }
  .awarenessContainer h1{
    color: var(--clr-primary);
    font-size: 1.5rem; 
    font-weight: 800;
    
  }