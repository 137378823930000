.progressSlider {
  margin: 10px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 400px;
  align-items: center;
  gap: 125px;
}
.progressContainer {
  display: flex;
  flex-direction: column;
  place-items: center;
  text-align: center;
  align-items: center;
  font-size: 0.75rem;
  max-height: 20%;
  
}
.dot {
  height: 1.5rem;
  width: 1.5rem;
  background-color: #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  padding: auto;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
}
.progressFieldset {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
}
.dot:hover,
.dot:focus {
  background-color: rgba(0, 0, 0, 0.5);
}

.current-dot {
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--clr-primary);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: auto;
  cursor: pointer;
  transition: background-color 150ms ease-in-out;
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
}

.current-dot:hover,
.current-dot:focus {
  background-color: #217396;
}

.progressSlider input[type="range"] {
  height: 30px;
  -webkit-appearance: none;
  margin: 0px;
  width: 400px;
}
.progressSlider input[type="range"]:focus {
  outline: none;
}
.progressSlider input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #3071a9;
  border-radius: 5px;
  border: 1px solid #000000;
}
.progressSlider input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 22px;
  width: 12px;
  border-radius: 11px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8.5px;
}
.progressSlider input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #3071a9;
}
.progressSlider input[type="range"]::-moz-range-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000;
  background: #3071a9;
  border-radius: 5px;
  border: 1px solid #000000;
}
.progressSlider input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 22px;
  width: 12px;
  border-radius: 11px;
  background: #ffffff;
  cursor: pointer;
}
.progressSlider input[type="range"]::-ms-track {
  width: 100%;
  height: 7px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.progressSlider input[type="range"]::-ms-fill-lower {
  background: #3071a9;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
.progressSlider input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
.progressSlider input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 22px;
  width: 12px;
  border-radius: 11px;
  background: #ffffff;
  cursor: pointer;
}
.progressSlider input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
.progressSlider input[type="range"]:focus::-ms-fill-upper {
  background: #3071a9;
}
