.or-group-container{
    width: 100%;
    height: auto;
    background-color: rgba(0,0,0,.1);
    border: 1px solid black;
    padding: 10px;
    margin: 5px;
    display:flex;
    flex-direction: column;
    color: black;
    position: relative;
    gap: 5px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0,0,0,.1);

}

.andButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 7px;
    font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
    border-radius: 6px;
    border: none;
  
    color: #fff;
    background: rgb(255, 140, 0);
     background-origin: border-box;
    box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    font-size: .75rem;
    transition: transform 200ms ease-out;
    margin-right: auto;
    cursor: pointer;
  }
  
  .andButton:hover{
      transform: scale(1.1);
  }
  
.andLabel{
    position: absolute;
    
    color: black;
    font-size: .5rem;
    left: 5%;
    top: 100%;
    font-weight: 700;
}