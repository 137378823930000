.funnelCard{
    width: 80%;
    height: 400px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid black;
    border-radius: 10px;
    overflow: hidden;
}

.cardHeader{
    
    width: 100%;
    height: 20%;
    padding-bottom: 0px;
    color: var(black);
    font-weight: 800;
    font-size: 1.5rem;
    
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    
}

.cardContainer{
    width: 100%;
    height: 90%;
    
    display: grid;
    grid-template-columns: 2fr 1.2fr;
    padding: 0px 10px 5px 10px;
    
    
}

.dataCard{
    width: 100%;
    height: 25%;
    border: 2px var(--clr-primary) solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
}
.dataCardTitle{
    font-size: .75rem;
    font-weight: 400;
    
}

.dataCardNumber{
    font-size: 1.25rem;
    font-weight: 600;
    
}
.funnelContainer{
    height: 100%;
    grid-column: 1;
}
.dataCardContainer{
    grid-column: 2;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 20px;
    
}