.sourceContainer{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: auto;
    overflow: hidden;
    margin-top: 20px;
}

.sourceContainer h1{
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
    color: var(--clr-primary-dark)
}
.dataTable {
    border-spacing: 0;
  
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    width: 90%;
    height: 80%;
  
    margin-top: 5px;
    margin-bottom: 20px;
  }
  
  .dataTable tr {
    height: 100%;
  }
  
  .dataTable tr td:first-child{
    
    font-weight: 600;
  }
  .dataTable th {
    background-color: var(--clr-primary);
    text-align: center;
    border-collapse: collapse;
    color: white;
    height: 20%;
  }
  
  
  
  .dataTable th,
  .dataTable td {
    padding: 0.5rem;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    text-align: center;
  }
  
  