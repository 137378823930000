.thumbnailCard {
  display: flex;
  flex-direction: column;
  
  align-items: center;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  padding: 5px;
  border-radius: 10px;
  z-index: 10;
  
  
}

.sortFields {
  font-size: 0.8rem;
}
.sortFields span {
  font-size: .75rem;
  color: black;
  font-weight: 800;
}

.thumbnail-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  width: 100%;
  
}
