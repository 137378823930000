.executiveSummaryBox{
    width: 90%;
    display: flex;
    flex-direction: column;
    border: 1px solid black;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0,0,0,.2);
    margin-inline: auto;
    margin-bottom: 10px;
    padding-bottom: 10px;
    margin-top: 20px;
}



.section{
    width: 98%;
    display: flex;
    flex-direction: column;
    margin-inline: auto;
}

.section-title{
    
    margin-inline: auto;
    color: var(--clr-primary);
    font-size: 1.5rem;
    font-weight: 800;

}

.section-ul{
    font-size: .75rem;
}
.section-ul.a{
    list-style-type:circle;
    
}

.bullet{ 
    margin-bottom: 10px;  
 }

 .bullet-intro{
    font-weight:bold;
 }