.arrowContainer {
  color: white;

  display: "flex";
  flex-direction: "row";
  padding-left: "8px";
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 600;
  
  margin: 0 3px;
  padding: 0;
}
