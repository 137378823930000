.assetDescription {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  font-weight: 400;
  flex-grow: 1;

}
.asset-description2 {
  display: flex;
  flex-direction: column;

  list-style-type: none;
  font-weight: 400;
}
.asset-description3 {
  display: flex;
  flex-direction: column;

  list-style-type: none;
  font-weight: 400;
}
.assetDescriptionContainer {
  grid-row: 2/3;
  grid-column: 2/4;
  display: flex;
  

  list-style-type: none;
  font-size: .75rem;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: rgba(0,0,0,.0);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.assetDescriptionContainer span {
  color: var(--clr-primary);
  font-weight: 800;
}


