.filter-container{
    width: 50%;
    height: auto;
    display: flex;
    padding: 0px;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    transition: all 1s ease-out;
    border: 1px black solid;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow:hidden;
    
}

.filter-container > * {
    flex-basis: 100%;
}


.settingsHeader {
    width: 100%;
    margin: 0px ;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(12,58,97,.7);
  }
  
  
  .carouselSizeFieldset {
    padding:  0 0rem ;
    margin: 0px 10px 0 0px;
    border: none;
    text-align: center;
    width: 10%;
    z-index: 100;
  }
  
  .carouselSizeLegend {
    color: white;
    font-size: 0.8rem;
    font-weight: 800;
    margin: 0;
    padding: 0;
    position: relative;
    top: 2px;
  }
  

  
  .settingsTitle{
    font-size: 1.5rem;
    font-weight: 800;
    margin: 10px;
    color: white;
  }
 
  

  .slider {
    outline: 0;
    border-width: 0 0 2px;
    border-color: grey;
    background-color: transparent;
  }
  .slider:focus {
    outline: none;
    border-color: var(--clr-primary);
  
  }
  
  .slider:active {
    outline: none;
  }


input[type="range"] {
    height: 25px;
    -webkit-appearance: none;
    margin: 0px;
    width: 100%;
    
  }
  input[type="range"]:focus {
    outline: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: var(--clr-primary);
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type="range"]::-webkit-slider-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 22px;
    width: 12px;
    border-radius: 11px;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -8.5px;
  }
  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: var(--clr-primary);
  }
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: 1px 1px 1px #000000;
    background: var(--clr-primary);
    border-radius: 5px;
    border: 1px solid #000000;
  }
  input[type="range"]::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 22px;
    width: 12px;
    border-radius: 11px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]::-ms-track {
    width: 100%;
    height: 7px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type="range"]::-ms-fill-lower {
    background: var(--clr-primary);
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type="range"]::-ms-fill-upper {
    background: var(--clr-primary);
    border: 1px solid #000000;
    border-radius: 10px;
    box-shadow: 1px 1px 1px #000000;
  }
  input[type="range"]::-ms-thumb {
    margin-top: 1px;
    box-shadow: 1px 1px 1px #000000;
    border: 1px solid #000000;
    height: 22px;
    width: 12px;
    border-radius: 11px;
    background: #ffffff;
    cursor: pointer;
  }
  input[type="range"]:focus::-ms-fill-lower {
    background: var(--clr-primary);
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: var(--clr-primary);
  }
  
  