.sortMenu{
    
    
    border-left: 1px black solid;
    border-right: 1px black solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
}

.sortMenu > input[type="checkbox"] {
    cursor: pointer;
}

.sortMenu > span{
    font-weight: 900;
    font-size: 1.5rem;
    color: var(--clr-primary-dark);
}



.sortSelect{
    
    margin: 5px;
    display:flex;
    flex-direction: row;
    width: 95%;
    gap: 5px;
    align-items: center;
    justify-content:flex-start;
}


.sortSelect > span{
    color:black;
    font-weight:500;
    width: 110px;
    padding:0;
    margin:0;
    
}

.sortSelect:first-child > span { margin-right: auto; }

.sortSelect:last-child  > span { margin-left: auto;  }