
.titleText{
    margin-left: .5rem;
    vertical-align: middle;
    color: white;
    font-size: 1.25rem;
    font-weight: 900;
    height: 100%;
  }
  .subTitleText{
    margin-left: .5rem;
    vertical-align: middle;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    height: 100%;
  }
  
  .titleLogoContainer {
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: auto;
    
    
    justify-content: flex-start;
  }
  
  .mainLogo {
    width: auto;
    height: 60px;
  }
  
  .navBar{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    
    flex: 1;
    
    
  }
  .navBar li {
    padding: 0px 20px;
    color: white;
    font-size:.75rem;
    text-align: center;
  }

  @media (max-width: 1000px) {
    .navBar li
    {
    padding: 0px 10px;
    }
    
    }
  .navBar a { 
    text-decoration: none;
    color: white;
    transition: hover 0.3s ease 0s;
  }

  .navBar a:hover{
    color: black;
  }
  .header{
    padding-right:  10px;
    min-height: 60px;
    width: 100%;
    display: flex;
    background-color: var(--clr-primary);
    justify-content: center;
    
  }

  .navBar a.active{
    font-weight: 800;
    color: var(--clr-primary-dark);
    text-decoration: underline;
  }
  