.main {
  width: 100%;
  height: 100%;
  padding-bottom: 300px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.audienceAnalyticsContainer {
  width: 90%;
  height: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  margin-top: 0px;
  background-image: linear-gradient(
    var(--clr-primary),
    var(--clr-primary-dark) 90%
  );

  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 1fr 2fr .5fr;
  grid-template-rows: repeat(6, 1fr) 0.5fr;
  justify-content: flex-start;
  border-radius: 10px;
}

.audienceTitle {
  color: white;
  font-weight: 800;
  font-size: 2.5rem;
  grid-column: 1/4;
  padding: 10px 10px;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  margin-inline: center;
  text-align: center;
  overflow:visible;
  min-width: 600px;
}

.audienceSummary {
  padding-left: auto;
  font-size: 1.25rem;
  grid-column: 1/4;
  grid-row: 2/6;
  border: 1px white solid;
  display: grid;
  height: 100%;
  justify-content: center;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(8, 1fr);
  text-align: center;
  border-radius: 10px;
  margin-left: 15%;
  margin-right: 40%;
  min-width: 200px;
}

.fragileTitle {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: white;
  grid-column: 1/3;
  padding-top: 20px;
  max-width: 80%;
  margin-inline: auto;
}

.statText {
  color: white;
  font-size: 1.25rem;
}

.fragileText {
  grid-column: 1/3;
  grid-row: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.platformAudiences {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: white;
  font-size: 1.5rem;
  grid-row: 4/5;
  grid-column: 1/4;
}

.bottleTitle {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: white;
  font-size: 1.5rem;
  width: 80%;
  text-align: center;
  position: relative;
}
.audienceTable {
  grid-row: 5/8;
  grid-column: 1/4;
}

.platformTitle {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  color: white;
  font-size: 1.25rem;
}

.platformNumber {
  color: white;
  font-size: 1rem;
}

.tableContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-inline: auto;
  margin-top: 20px;
  padding: 0;
  gap: 50px;
}
.hidden{
    visibility: hidden;
}
.bottle {
  grid-column: 4/6;
  grid-row: 1/8;
  width: 648px;
  height: 600px;
  display: flex;
  flex-direction: column;

  place-items: center;

  padding-top: 10px;
  margin-top: 0;
  position: relative;
}

.bottleImg {
  margin-top: auto;
  margin-bottom: auto;
  width: auto;
  height: 100%;
}

.funnelContainer {
  margin: auto;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
}

.barContainer {
  margin: auto;
  width: 90%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  stroke: white;
  stroke-width: 2;
  fill: none;
}



  .fragileIdentifiedPath {
    animation: dash 1s ease-in forwards;
    animation-delay: 2.5s;
    visibility: hidden;
  }
  .fragileIdentifiedText{
      animation: reveal 1s ease-in forwards;
      animation-delay: 2.5s;
      opacity: 0%;
  }
  .fragileNurturedPath {
    animation: dash 1s ease-in forwards;
    animation-delay: 5s;
    visibility: hidden;
  }
  .fragileNurturedText{
      animation: reveal 1s ease-in forwards;
      animation-delay: 5s;
      opacity: 0%;
  }
 
  .fragileEngagedPath {
    animation: dash 1s ease-in forwards;
    animation-delay: 8s;
    visibility: hidden;
  }
  .fragileEngagedText{
      animation: reveal 1s ease-in forwards;
      animation-delay: 8s;
      opacity: 0%;
  }


  .mvsPath {
    animation: dash 1s ease-in forwards;
    animation-delay: 10s;
    visibility: hidden;
  }
  .mvsText{
      animation: reveal 1s ease-in forwards;
      animation-delay: 10s;
      opacity: 0%;
  }

.totalPath {
    animation: dash 1.5s ease-in forwards;
    animation-delay: 12s;
    visibility: hidden;
  }
  .totalText{
      animation: reveal 1.5s ease-in forwards;
      animation-delay: 12s;
      opacity: 0%;
  }

@keyframes reveal{
    from{
        opacity: 0%;
    }
    to{
        opacity: 100%;
    }
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
    visibility: hidden;
  }
  to {
    stroke-dashoffset: 0;
    visibility: visible;
  }
}

.milksvg {
  height: 100%;
  width: 100%;
  margin-inline: auto;
  position: absolute;
  overflow: visible;
}

.milkBold {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  fill: white;
  font-size: 2.5em;
  letter-spacing: 0.5px;
}

.milkNormal {
  fill: white;
  font-size: 2em;
}

.journeySelector{
  width: 90%;
  margin-top: 10px;
  display: flex;
  margin-inline: auto;
  display: flex;
  align-items: center;
}

.journeyTitle{
  font-size: 2.25rem;
  font-weight: 800;
  color: var(--clr-primary);
  margin-inline: auto;
  width: 100%;
}

.journeyTitleContainer{
  width: 100%;
  text-align: center;
}

.journeyHeader{
  display: flex;
  flex-direction: column;
  place-items: center;
}


.audienceGrowthContainer{
  width: 80%;
  aspect-ratio: 16/9;
  overflow: visible;
  padding: 10px;
  margin-inline:auto;
}


.completedJourneysContainer{
  width: 80%;
  aspect-ratio: 20/9;
  overflow: visible;
  padding: 10px;
  margin-inline:auto;
}


.funnelContainer {
  margin: auto;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 2px;
  padding: 0;
  gap: 20px;
}
.journeyHeader{
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
}