.summaryContainer{
    display: flex;
    flex-direction: column;
    border: 2px var(--clr-primary) solid;
    place-items: center;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
    width: 100%;
    margin-inline: auto;
    padding-bottom: 5px;
}

.boxes{
    
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

    
    
}

