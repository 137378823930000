.logout {
  background-color: white;
  border-radius: 10px;
  padding: 2px 5px;
  border: none;
  margin: 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.1s ease-out;
  width: 75px;
}

.logout:hover {
  background-color: var(--clr-close-button);
}
