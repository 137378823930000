.dataTable {
  border-spacing: 0;

  border-top: 0.5px solid black;
  border-left: 0.5px solid black;
  width: 100%;
  box-shadow: 5px 5px 10px rgba(0,0,0,.2);

/*  margin-top: 20px;
  margin-bottom: 20px;*/
}
.platformCell{
    background-color: white;
    color: black;
    height: 40%;
    width: 10%;
    font-size: 1.5rem;
    font-weight: 700;
    border-bottom: 2px solid black;
}

.platformContainer{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    place-items: center;
}

.journeyStage { 
    background-color: var(--clr-primary);
    color: white;
    font-size: 1.25rem;

  }

  .resultType{
    text-align: center;
    color: black;
    font-weight:800;
  }
.dataTable th {
  
  text-align: center;
  border-collapse: collapse;
  
  
}
.rowTitle {
  font-weight: 800;
  text-align: center;
  max-width: 20%;
}

.dataTable th,

.dataTable td {
  padding: 0.25rem;
  border-right: 0.5px solid black;
  border-bottom: 0.5px solid black;
  padding-inline: auto;
  
}

.rowData {
  text-align: center;
  font-weight: 500;
}

.actualColumn {
  display: flex;

  justify-content: center;
  align-items: center;
  gap: 5px;
}

.actualColumn td {
  height: 0;
}
