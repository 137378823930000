
.container {
  display: flex;
  flex-direction: column;
  place-items: center;
  width: 100%;
  padding: 10px 10px 0px 10px;
  grid-column: 2/4;
  grid-row: 3/9;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
}

.select-legend-header {
  display: flex;
  width: 100%;
}

.comparison-select-container {
  width: auto;
  margin: 0 auto 0 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
}

.comparison-select-container span{
  font-size: .75rem;
  font-weight: 600;
}



.legend {
  display: flex;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border: 0.5px rgb(0, 0, 0, 0.25) solid;
  padding:0px 5px;
  font-size: .75rem;
  height: auto;
}
.legend-bar {
  width: 1.25rem;
  height: 0.625rem;
  margin-right: 2px;
}

.color-primary{
  background-color: var(--clr-primary);
}

.color-dark{
  background-color: var(--clr-primary-dark);
}
.comparisonLegend {
  width: 20px;
  height: 10px;
  background-color: var(--clr-primary-dark);
  margin-left: 10px;
  margin-right: 2px;
}

.left-margin{
  margin-left: 10px;
}


.metric-row-container{
    
  display:flex;
  flex-direction: column;
  
  height: 100%;
  margin: 10px 30px 0px 30px;
  width: 100%;
  place-items: center;
  gap: 5px;
  
}

  
  .comparisonContainer {
    width: 100%;
    height: 100%;
  }
  
  .comparisonItem {
    display: flex;
    flex-direction: column;
    place-items: center;
  }
  

.assetMetricsRow{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    gap: 5px;
    margin: 0px 5px;
}