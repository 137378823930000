.thumbnail {
  
  width: 100%;
  
  margin: 0;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  object-fit: contain;
  cursor: pointer;
  margin: 0em ;
  flex-grow: 1;
  

  
}
.thumbnail:hover {
  transform: scale(1.01);
  
}

.thumbnail-video {
  
  width: auto;
  max-height: 200px;
}

.thumbnail-image:hover{
  border-radius: 0;
}
.thumbnail-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin-inline: "auto" ;
  
}

.organicPaidLabel{
  padding: 0;
  margin: 0;
  height: 10px;
  width: 10px;
  
  top: 0%;
  right: 0%;
  background-color: var(--clr-primary);
  border-radius: 0%;
  font-size: .5rem;
  font-weight: 700;
  color: white;
  display: flex;
  place-items: center;
  justify-content: center;
}

.platformIcon{
 
}