.main {
    width: 100%;
    margin: 10px 0  100px 20px;
    overflow-x: hidden; /* Hide horizontal scrollbar */
    display: flex;
  flex-direction: column;
    gap: 5px;
  }


.uploadContainer{
  border: 1px var(--clr-primary) solid; 
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: 80%;
  margin-inline: auto;
  
}
  .error{
    color: red;
  }

  .success{
    color: green;
  }