.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  z-index: 100;

  overflow-y: initial;

  width: 90%;
  height: 70%;
  background-color: white;
  border: 2px solid var(--clr-primary);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  
}

@media (min-width: 768px) {
  .modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  
}

.close-button {
  position: absolute;
  margin-right: 4px;
  top: 0px;
  right: 0px;
  z-index: 10;
  color: var(--clr-primary-dark);
  transition: all 200ms ease;
}
.close-button:hover {
  color: var(--clr-close-button);
  cursor: pointer;
}
