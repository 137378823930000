.funnelContainer {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0;
  height: 100%;
}

.layer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  font-size: 0.8rem;
  position: relative;
  z-index: 100;
  animation: slideDown 2s forwards;
  

}

@keyframes slideDown {
    from {
      transform: translate3d(0, -100%, 0);
      visibility: hidden;
    }
  
    to {
      transform: translate3d(0, 0, 0);
    }
  }

.verticalLine {
    padding: 0;
    margin: 0;
    height: 25%;
    width: 1px;
    border-left: 1px solid gray;
    position:absolute;
   
    left: 50%;
    top: 90%;
}

.layerContainer{
    width: 100%;
    height: 100%;
    position:relative;
}


.pct{
    font-weight: 900;
}


