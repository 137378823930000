.main {
  width: 100%;
  margin: 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  padding-bottom: 100px;
}

.header {
  display: flex;
  width: 90%;
  align-items: center;
  margin-inline: auto;
  justify-content: center;
  height: auto;
  margin-top: 10px;
}
.executivesummary {
  width: 90%;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-inline: auto;
}

.settingsIcon {
  margin: 10px 10px 5px auto;
  padding: 0 0px 0 0;
  display: flex;
  justify-content: flex-end;
  color: black;
  transition: all 100ms ease-in;
}
.settingsIcon:hover {
  color: var(--clr-primary);
  cursor: pointer;
}
.executivesummary h1 {
  font-size: 2rem;
  color: var(--clr-primary-dark);
  font-weight: 900;
}

.lastUpdateHeader {
  width: 100%;
}

.lastUpdateHeader span {
  margin: 2px 3px;
}

.lastUpdateLabel {
  font-weight: 800;
  font-size: 0.75rem;
  color: var(--clr-primary-dark);
}

.lastUpdateDate {
  font-weight: 800;
  font-size: 0.75rem;
  color: var(--clr-close-button);
}
.daisyAccountSummaryContainer {
  width: 30%;
  margin: 0px auto;
}
.daisyAvatarContainer {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 800;
  font-size: 1.5rem;
}

.daisyAvatar {
  border-radius: 50%;
}

.daisyAccountStats {
  display: flex;
}
.statContainer {
  display: flex;
  gap: 5px;
  margin: 0px 5px;
}

.statNumber {
  font-weight: 800;
  font-size: 0.75rem;
}

.statLabel {
  font-weight: 400;
  font-size: 0.75rem;
}

.daisyDescription {
  font-size: 0.75rem;
  margin: 0px 5px;
}

.timeSeriesContainer {
  width: 90%;
  margin-inline: auto;
  display: flex;
  gap: 10px;
}
.headerChartsContainer {
  width: 100%;
}
.topAssetsContainer {
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  margin-inline: auto;

  gap: 10px;
  margin-bottom: 20px;
}
.topAssets {
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-top: 20px;
}
.topAssets h1 {
  font-size: 2rem;
  color: var(--clr-primary-dark);
  font-weight: 900;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.barCharts {
  margin-inline: auto;
  margin-top: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-items: center;
  margin-top: 20px;
  padding: 0;
  gap: 20px;
}
.aggregateGroup {
  margin-left: auto;
  margin-right: 10px;
}
.aggregates {
  width: 90%;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-inline: auto;
  margin-bottom: 200px;
}
.aggregates h1 {
  font-size: 2rem;
  color: var(--clr-primary-dark);
  font-weight: 900;
}

.summaryContainer {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  margin-inline: auto;
}

.daisyDateWrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  
  
}

.daisyDateWrapper span{
  margin-left: auto;
  font-weight: 800;
  color: black;
}
.daisyDatePickerWrapper{
  width: auto;
  margin-left:auto;
  
}

.daisyDatePicker{
  text-align: center;
}

.daisyDatePicker:hover{
  cursor: pointer;
  
}

.daisyPostTableContainer{
  width: 90%;
  max-height: 40rem;
  overflow: auto;
  margin-inline: auto;
  box-shadow: 5px 5px 5px rgba(0,0,0,.2);
}


.barChartWrapper{
  width: 100%;
  aspect-ratio: 16/9;
  overflow: visible;
    padding: 10px;
}


.timeSeries{
  width: 100%;
  aspect-ratio: 16/9;
  overflow: visible;
    padding: 10px;
}