@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities"; 

:root{
  --clr-primary: rgb(50, 176, 240);
  --clr-primary-dark: rgb(12,58,97);
  --clr-secondary: white;
  --clr-language-french: rgb(0, 68, 184);
  --clr-language-english: rgb(83,135,226);
  --clr-close-button:  rgb(218, 51, 51);
 
}

*:focus {
  outline: 0;
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

/*
  1. Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
  
}

/*
  2. Remove default margin
*/
* {
  margin: 0;
}

/*
  3. Allow percentage-based heights in the application
*/
html, body {
  height: 100%;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  8. Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  9. Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}

[data-amplify-authenticator]{
  
  height: 100vh;
}

