.main {
  width: 100%;
  margin: 0 0 100px 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.header {
  width: 100%;
  display: flex;
}
.settingsIcon {
  margin: 10px 10px 5px auto;
  padding: 0 0px 0 0;
  display: flex;
  justify-content: flex-end;
  color: black;
  transition: all 100ms ease-in;
}
.settingsIcon:hover {
  color: var(--clr-primary);
  cursor: pointer;
}

.carousel {
  height: 500px;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.presetSelector {
  display: flex;
  flex-direction: column;
  place-items: center;
}

.presetSelector span {
  font-size: 1.5rem;
  color: var(--clr-primary);
  font-weight: 800;
}

.presetContainer {
  width: 70%;
  display: flex;
  margin-inline: auto;
  justify-content: space-evenly;
  margin-top: 15px;
  padding-bottom: 15px;
  border: 1px solid var(--clr-primary);
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0,0,0,.2);
}

.executivesummary {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-inline: auto;
  margin-top: 0px;
}

.barCharts {
  margin-inline: auto;
  margin-top: 20px;
  width: 100%;
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
  padding: 0;
  gap: 20px;
}

.aggregates {
  width: 100%;
  display: flex;
  flex-direction: column;
  place-items: center;
  margin-inline: auto;
  margin-bottom: 200px;
  margin-top: 50px;
}
.aggregates h1 {
  font-size: 2rem;
  color: var(--clr-primary-dark);
  font-weight: 900;
}