
.title-container {
  position: relative;
  
  width: 100%;
  
  
  margin: 0;
  padding: 0;
  white-space: break-spaces;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  text-align: center;
 
}

.title-container h1 {
  font-weight:800;
  margin-bottom: 0px;
  padding: 0;
}

.language-french {
  color: var(--clr-language-french);
}

.language-english {
  color: var(--clr-language-english);
}

.title-container h2 {
  font-weight: 600;

  color: rgba(0, 0, 0, 0.5);
  
  margin-top: -2px;
  margin-bottom: 0px;
  padding: 0;
}

.organicPaidLabel{
  padding: 0;
  margin: 0;
  height: 1.25rem;
  width: 1.25rem;
  
  
  background-color: var(--clr-primary);
  border-radius: 50%;
  font-size: 1rem;
  font-weight: 700;
  color: white;
  display: flex;
  place-items: center;
  justify-content: center;
}