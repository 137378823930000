


.barCharts {
    margin-inline: auto;
    margin-top: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    justify-items: center;
    margin-top: 20px;
    padding: 0;
    gap: 20px;
  }
  .aggregateGroup {
    margin-left: auto;
    margin-right: 10px;
  }
  .aggregates {
    width: 90%;
    
  }
  .aggregates h1 {
    font-size: 2rem;
    color: var(--clr-primary-dark);
    font-weight: 900;
  }

  .main{
    width: 100%;
    
    display: flex;
    justify-content: center;
  }

  .wrapper{
    aspect-ratio: 16/9;
    width: 50%;
  }