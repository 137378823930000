.videoWrapper {
  height: 100%;
  margin: 0px 0px;
  border: 1px black solid;
  overflow: hidden;
  
  border-radius: 10px;
  box-shadow: 3px 3px 5px rgba(0,0,0,.2);
  padding: 5px;
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;

}
.download{
  
  font-size: .5rem;
  margin: 2px 2px 0px auto;
  background-color: rgba(0,0,0,.2);
  padding: 2px;
  border-radius: 5px;
  color:black;
  transition: all 400ms ease-out;
  border: none;
}


.download:hover{
  transform:scale(1.02);
  cursor:pointer;
  color: white;
  background-color: var(--clr-primary);
}

.videoContainer{
  grid-row: 1/9;
  grid-column: 1/2;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px 5px;
  position: relative;
}

.assetVideo {
  width: 100%;
  height: 100%;

  display: block;
}


.assetImage {
  max-width: 100%;
  max-height: 100%;
  width:auto;
  height:auto;
}