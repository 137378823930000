.modal {
    
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
    
    z-index: 10000;
    
    overflow-y: initial;
  }

  @media (min-width: 768px) {
    .modal {
      position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background: rgba(0, 0, 0, 0.75);
  }