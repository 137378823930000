.topAssetContainer {
  width: auto;
  
  display: flex;
  flex-direction: column;
  place-items: center;
  margin: 10px;
  border: 1px black solid;
  border-radius: 10px;
  overflow: hidden;
  height: 500px;
  
}

.header {
  
  color: white;
  text-align: center;
  font-weight: 800;
  
  width: 100%;
  max-height: 10%;
  margin-bottom: 10px;
  
  overflow: hidden;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.english {
  background-color: var(--clr-language-english);
}

.french {
  background-color: var(--clr-language-french);
}
