
.wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    overflow: visible;
}

.wrapper h1{
    color: var(--clr-primary-dark);
    font-size: 1.5rem;
    
}
.customTooltipContainer{
    background-color: white;
    display: flex;
    flex-direction: column;
    place-items: center;
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 10px;
}

.customTooltipTitle{
    font-size: .75rem;
    font-weight: 800;
    color:var(--clr-primary)
    

}
.customTooltipLabel{
    font-size: .75rem;
    font-weight: 400;
    color:black;
    

}

recharts-surface {
    overflow: visible;
}