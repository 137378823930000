.carouselWrapper {
  padding: 0 10px 0px 10px;
  width: 100%;

  margin: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  place-items: center;
}

.sliderContainer {
  display: flex;

  justify-content: space-between;

  width: 100%;

  padding: 0px;
  margin: 0;
  overflow: hidden;
  align-items: stretch;

  flex-grow: 1;
}

.carouselSlider {
  display: flex;
  justify-content: flex-start;
  padding: 0;
  margin: 0px;
  width: 90%;
  transition: transform 300ms ease-in-out;
  flex-grow: 1;
  height: auto;
}


.carouselHeader{
  display: flex;
  
  width: 100%;
  
  justify-content: space-between ;
  place-items: center;
}
.carouselTitle {
  color: var(--clr-primary-dark);
  width: auto;
  
  font-size: 2rem;
  font-weight: 800;
  
  margin-top: 0px;
  margin-inline: auto;
}

.exportButton{
  margin-top:auto;
  padding-right: 5px;
  
}
.exportButton:hover{
  cursor: pointer;
}
.handle {
  border: none;
  border-radius: 1rem;
  flex-grow: 0;
  width: 3rem;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 10;
  margin: 0.25rem 0;
  cursor: pointer;
  font-size: 5rem;
  display: flex;
  align-items: center;
  justify-content: left;
  line-height: 0;
  color: white;
  padding: 10px;
  transition: background-color 150ms ease-in-out;
}

.left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.handle:hover,
.handle:focus {
  background-color: rgba(0, 0, 0, 0.5);
}

.handle:hover .handleText {
  transform: scale(1.2);
  color: var(--clr-primary);
}

.handle::after {
  color: white;
}

.handleText {
  transition: transform 150ms ease-in-out;
  margin: 0;
  padding: 0;
}
